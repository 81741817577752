/**
 * Custom Site Functionality
 * Author - Edge Marketing
 *
 * Version - 1.2
 */

$(document).ready(function(){
    // new Swiper ('.swiper-container', {
    //     // Optional parameters
    //     direction: 'horizontal',
    //     loop: true,
    //     pagination: '.swiper-pagination',
    //     paginationClickable: true,
    //     nextButton: '.swiper-button-next',
    //     prevButton: '.swiper-button-prev',
    //     effect: 'fade',
    //     autoplay: 4000,
    //     autoplayDisableOnInteraction: false,
    //     speed: 700,
    //     lazyLoading: true,
    //     lazyLoadingInPrevNext: true,
    //     lazyLoadingInPrevNextAmount: 1
    // });

    $('.clear-height').css("height","auto");

    $("img.img-lightbox").click(function () {
        $.fancybox.open('<img class="img-fluid img-lightbox" src="' + this.src + '">');
    });

    $('.scroll-top').click(function () {
        $('body,html').animate({
            scrollTop: 0
        }, 500);
        return false;
    });

    $('.gallery-hover').each(function() {
        var $this = $(this);
        var $caption = $this.find('.hover-caption');
        $this.hover(function() {
            $caption.stop().fadeIn(200);
        }, function() {
            $caption.stop().fadeOut(200);
        });
    });

    $("form").submit(function() {
        swal({
            title: "Processing...",
            icon: "warning",
            buttons: {
                confirm: {
                    closeModal: false
                },
            },
            closeOnClickOutside: false,
            closeOnEsc: false,
        });
    });

    var fullScreenVideo = fullScreenVideo || {};
    var wistiaVideoId = $('.backgroundVideo').data('wistia-id');

    fullScreenVideo = {
        name: 'fullScreenVideo',
        backgroundVideo: wistiaVideoId,
        // backgroundVideoDiv: wistiaVideoDOM,

        embedVideo: function()
        {
            var videoOptions = {
                autoPlay: true,
                fitStrategy: 'cover',
                endVideoBehavior: 'loop',
                muted: true,
                controlsVisibleOnLoad: false,
                playbar: false
            };

            wistiaEmbed = Wistia.embed(fullScreenVideo.backgroundVideo, videoOptions);
        },

        fixTextPosition: function()
        {
            var width = $( window ).width();
            var height = $( window ).height();
            // $("#video_container").css("width", width).css("height", height - 120);
        },
    };

    setTimeout(function() {
        // Start loading the video
        fullScreenVideo.embedVideo();

        fullScreenVideo.fixTextPosition();
        // If the widow is resized, resize the videos
        $(window).resize(function () {fullScreenVideo.fixTextPosition();});
    }, 500);

    $('[name="renewal_type"]').on('change input', function () {
        console.log($(this).val());
        if ($(this).val() === 'Renewal') {
            $('.membership_number_group').removeClass('d-none');
        } else {
            $('.membership_number_group').addClass('d-none');
        }
    });

    /** Start of Signature Functionality **/
    let signatureCanvas = $("#signature-canvas");
    let signatureInput = $("#signature-input");

    // Create global variable for signaturePad
    window.signaturePad = false;

    if (signatureCanvas.length) {
        signaturePad = new SignaturePad(signatureCanvas[0], {
            onEnd: function() {
                signatureInput.val(btoa(this.toDataURL()));
            }
        });

        $("#signature-clear").click(function(e) {
            e.preventDefault();
            signaturePad.clear();
        });

        let sizeSignature = function() {
            signatureCanvas.attr("width", signatureCanvas.parent().innerWidth());
            signaturePad.clear();
        };

        sizeSignature();
        $(window).resize(sizeSignature);
    }

    $('#contact_signature_upload').each(function() {
        let input    = $(this),
            label    = input.next(),
            labelVal = label.html();

        input.on( 'change ', function(e) {
            if( this.files.length > 0 ) {
                let file = this.files[0];
                let MB = 1000000;
                let errors = [];
                if (file.size > MB) errors.push('The file must be 1MB or smaller.');
                if (file.type != "image/jpeg" && file.type != "image/png") errors.push('The file must be a .jpg or .png file.');
                if(!errors.length) {

                    let img = new Image();
                    let reader = new FileReader();

                    img.onload = function () {
                        let cWidth = signatureCanvas.innerWidth();
                        let cHeight = signatureCanvas.innerHeight();

                        if (cWidth > cHeight)
                            signatureCanvas[0].getContext('2d')
                                .drawImage(img, 0, 0, cHeight * img.width / img.height, cHeight);
                        else
                            signatureCanvas[0].getContext('2d')
                                .drawImage(img, 0, 0, cWidth, cWidth * img.height / img.width);

                        signatureInput.val(btoa(signaturePad.toDataURL()));
                    };

                    reader.onload = function (e) {
                        img.src = e.target.result;
                        // signaturePad.fromDataURL(e.target.result);
                        // signatureInput.val(e.target.result).change();
                    };

                    reader.readAsDataURL(file);
                } else {
                    let messageText = '';
                    for (let i = 0; i < errors.length; i++) {
                        messageText += errors[i] + "<br />";
                    }
                    swal({
                        title: "Error!",
                        content: {
                            element: "div",
                            attributes: {
                                innerHTML: messageText
                            },
                        },
                        icon: "error"
                    });
                }

            }
        });
    });
    /** End of Signature Functionality **/
});
